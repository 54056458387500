<script setup lang="ts">
import {
  ref,
  computed,
  type PropType,
  onMounted,
  onUnmounted,
  watch,
  onBeforeUnmount,
} from 'vue';
import Collapse from '@/components/Collapse.vue';
import type { TenantDesign } from '@/types/player-config';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useI18n } from 'vue-i18n';
import {
  fetchToken,
  fetchMediaChapters,
  fetchMediaChapter,
  fetchRecommendations,
} from '@/helpers/API';
import type { Asset, MediaData, Chapter, Recommendation } from '@/types/media';
import MediaDetails from '@/components/Media/MediaDetails.vue';
import videojs from 'video.js';
import Slider from '@/components/Slider/Slider.vue';
import { extWidgetRecommendationConfig } from '@/utils/grid.ts';

type VideoJsPlayer = typeof videojs.players;

const {
  assetId,
  tenantName,
  height,
  width,
  recommendationsChannelId,
  recommendationsMaxCount,
  accessToken: initialAccessToken,
} = defineProps({
  assetId: {
    type: String,
    required: true,
  },
  tenantName: {
    type: String,
    required: true,
  },
  playerSettings: {
    type: Object as PropType<TenantDesign>,
  },
  channelId: {
    type: String,
  },
  accessToken: {
    type: String,
  },
  width: {
    type: String,
    default: '100%',
  },
  height: {
    type: String,
  },
  recommendationsChannelId: {
    type: String,
  },
  recommendationsMaxCount: {
    type: Number,
    default: 10,
    validator: (value: number) => {
      const isValid = value >= 0 && value <= 50;
      if (!isValid) {
        console.warn(
          `[Warning] recommendationsMaxCount should be between 0 and 50. Received: ${value}`,
        );
      }
      return isValid;
    },
  },
  showStats: {
    type: Boolean,
    default: false,
  },
});

dayjs.extend(duration);
dayjs.extend(localizedFormat);

const data = ref<MediaData>();
const mediaWidget = ref();
const { t } = useI18n();
const chapters = ref<Chapter>();
const detailsOpen = ref();
const accessToken = ref(initialAccessToken);
const recommendations = ref<Recommendation[]>();
const player = ref();
const isRecommendationsMobile = ref<boolean>(false);
const isCollapseMobile = ref<boolean>(false);
const recommendationsPerRow = ref<number>(5);
const containerEl = ref<HTMLElement>();
const metaContainerEl = ref<HTMLElement | null>(null);
const metaEl = ref<HTMLElement | null>(null);
const collapseEl = ref<HTMLElement | null>(null);
let resizeObserver: ResizeObserver | null = null;

const isImage = computed(() => data.value && data.value.type === 'image');

const showRecommendations = computed(
  () =>
    recommendationsChannelId &&
    recommendations.value &&
    accessToken.value &&
    recommendationsMaxCount > 0,
);

const formattedLength = computed(() => {
  if (!data.value?.length) {
    return '';
  }
  const durationObj = dayjs.duration(data.value.length);

  if (durationObj.hours()) {
    return dayjs.duration(data.value.length).format('H:mm:ss');
  }
  return dayjs.duration(data.value.length).format('m:ss');
});

const formattedPublishDate = computed(() => {
  if (!data.value?.publishDate) return '';
  return dayjs(data.value.publishDate).format('L');
});

const handleAssetLoad = (asset: Asset) => {
  data.value = {
    id: asset.id,
    title: asset.name,
    length: asset.file.durationInMilliseconds,
    publishDate: asset.audit.createdAtUtc,
    views: asset.usage.views,
    type: asset.type,
    description: asset.description,
  };
};

const timeSeekSelect = (selectedTime: number) => {
  if (
    !isImage.value &&
    selectedTime !== undefined &&
    selectedTime >= 0 &&
    player
  ) {
    player.value.currentTime(selectedTime / 1000);
    player.value.play();
  }
};

const getToken = async () => {
  const res = await fetchToken(tenantName);
  accessToken.value = res.accessToken;
};

const getChapters = async () => {
  if (accessToken.value) {
    const res = await fetchMediaChapters({
      id: assetId,
      accessToken: accessToken.value,
    });
    if (res.data.length) {
      const chapterDetails = await fetchMediaChapter({
        id: assetId,
        accessToken: accessToken.value,
        chapterId: res.data[0].id,
      });
      chapters.value = chapterDetails.data;
    }
  }
};

const getRecommendations = async () => {
  if (accessToken.value) {
    const perPage = recommendationsMaxCount > 50 ? 50 : recommendationsMaxCount;
    const res = await fetchRecommendations({
      id: assetId,
      accessToken: accessToken.value,
      channelId: recommendationsChannelId,
      perPage,
    });
    recommendations.value = res.data.mediaAssets;
  }
};

const updateCardsPerRow = (width: number) => {
  const config = extWidgetRecommendationConfig.find((c) => width <= c.maxWidth);
  isRecommendationsMobile.value = config!.isMobile;
  recommendationsPerRow.value = config!.cardsPerRow;
};

const handleCollapse = (isOpen: boolean) => (detailsOpen.value = isOpen);

const checkCollapsePosition = async () => {
  if (metaContainerEl.value && metaEl.value && collapseEl.value) {
    const containerWidth = metaContainerEl.value.getBoundingClientRect().width;
    const combinedWidth =
      metaEl.value.getBoundingClientRect().width +
      collapseEl.value.getBoundingClientRect().width;

    isCollapseMobile.value = combinedWidth > containerWidth;
  }
};

watch(
  accessToken,
  (token) => {
    if (!token) {
      return getToken();
    }
    getChapters();
    if (recommendationsChannelId && recommendationsMaxCount > 0) {
      getRecommendations();
    }
  },
  { immediate: true },
);

watch([metaContainerEl, metaEl, collapseEl, chapters, data], () => {
  if (metaContainerEl.value && metaEl.value && collapseEl.value) {
    checkCollapsePosition();
  }
});

onMounted(() => {
  const handleAssetEvent = (event: CustomEvent<Asset>) => {
    handleAssetLoad(event.detail);
  };
  const handlePlayerEvent = (event: CustomEvent<{ player: VideoJsPlayer }>) => {
    player.value = event.detail.player;
  };

  const updateCardsCols = () => {
    if (containerEl.value) {
      updateCardsPerRow(containerEl.value.offsetWidth);
    }
  };

  const updateLayout = () => {
    checkCollapsePosition();
    updateCardsCols();
  };

  resizeObserver = new ResizeObserver(updateLayout);
  if (containerEl.value) {
    resizeObserver.observe(containerEl.value);
    updateCardsCols();
  }

  mediaWidget.value?.addEventListener('assetLoaded', handleAssetEvent);
  mediaWidget.value?.addEventListener('playerLoaded', handlePlayerEvent);
  onUnmounted(() => {
    mediaWidget.value?.removeEventListener('assetLoaded', handleAssetEvent);
    mediaWidget.value?.removeEventListener('playerLoaded', handlePlayerEvent);
  });
});

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.disconnect();
    resizeObserver = null;
  }
});
</script>

<template>
  <div
    class="sd-extended-media-widget-container"
    :style="{ width }"
    ref="containerEl"
  >
    <sd-media-widget
      :style="{ width, height }"
      ref="mediaWidget"
      class="sd-media-widget"
      :accessToken="accessToken"
      :tenantName="tenantName"
      :assetId="assetId"
      :width="width"
      :height="height"
      :playerSettings="playerSettings"
      :channelId="channelId"
    />
    <div
      class="sd-extended-media-widget-meta"
      v-if="data"
      ref="metaContainerEl"
    >
      <div class="sd-extended-media-widget-info" ref="metaEl">
        <span v-if="showStats"> {{ formattedPublishDate }}, </span>
        <span class="sd-extended-media-widget-length" v-if="formattedLength">
          {{ `${formattedLength}${showStats ? ',' : ''}` }}
        </span>
        <span v-if="showStats"> {{ data.views }} {{ t('common.views') }} </span>
      </div>
      <div
        ref="collapseEl"
        :class="[
          'sd-extended-media-widget-collapse',
          { 'collapse-mobile': isCollapseMobile },
        ]"
      >
        <Collapse
          v-if="chapters || data.description"
          @toggleCollapse="handleCollapse"
        >
          {{ t('common.moreAbout') }}
          {{ t(`common.${data.type}`) }}
        </Collapse>
      </div>
      <h2 class="sd-extended-media-widget-title">
        <span>{{ t(`common.${data.type}`) }}:</span>
        {{ data.title }}
      </h2>
    </div>
    <MediaDetails
      :chapters="chapters"
      :media-details="data"
      :details-open="detailsOpen"
      @time-select="timeSeekSelect"
    />
    <Slider
      v-if="showRecommendations"
      :access-token="accessToken!"
      :tenant-name="tenantName"
      :data="recommendations!"
      :title="t('common.recommendations')"
      :isMobile="isRecommendationsMobile"
      :cardsPerRow="recommendationsPerRow"
    />
  </div>
</template>

<style>
.sd-extended-media-widget-container {
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  background-color: rgb(0, 0, 0, 0.05);
}

.sd-extended-media-widget-meta {
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  flex-wrap: wrap;
}

.sd-extended-media-widget-info {
  display: flex;
  gap: 0.2rem;
  padding-right: 0.5rem;
}

.sd-extended-media-widget-title {
  font-size: 1.25rem;
  font-weight: 300;
  margin: 0.5rem 0 0;
  width: 100%;
}

.collapse-mobile {
  margin: 0.5rem auto 0;
  order: 3;
}
</style>
