import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { initMatomo } from '@/matomo';
import type { App } from 'vue';
import { createI18n } from 'vue-i18n';
import en from '@/locales/en.json';
import de from '@/locales/de.json';

export const mediaPluginWrapper = {
  install(Vue: App) {
    const language = navigator.language || navigator.languages[0];
    const i18n = createI18n({
      locale: language.startsWith('de') ? 'de' : 'en',
      fallbackLocale: 'en',
      messages: { en, de },
      legacy: false,
    });
    Vue.use(i18n);
    Vue.component('font-awesome-icon', FontAwesomeIcon);
    initMatomo(Vue);
  },
};
