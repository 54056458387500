<script setup lang="ts">
import { onMounted, type PropType, ref } from 'vue';
import { getThumbnail } from '@/helpers/API';
import PlayIcon from '@/public/playericon.png';
import type { Recommendation } from '@/types/media.ts';

const { data, tenantName, accessToken } = defineProps({
  data: {
    type: Object as PropType<Recommendation>,
    required: true,
  },
  accessToken: {
    type: String,
  },
  tenantName: {
    type: String,
  },
});

const emit = defineEmits(['handleFocusVideo']);

const imgSrc = ref<string>();

const handleFocus = () => emit('handleFocusVideo', data);

onMounted(async () => {
  if (data.thumbnail.url) {
    imgSrc.value = await getThumbnail({
      url: data.thumbnail.url,
      accessToken: accessToken,
      tenantName: tenantName,
    });
  }
});
</script>

<template>
  <div class="slider-card" @click="handleFocus">
    <div class="slider-card-image-wrapper">
      <img :src="imgSrc" class="card-image" alt="content" v-if="imgSrc" />
      <div v-else class="image-placeholder"></div>
      <img class="play-icon" :src="PlayIcon" alt="Play Icon" />
    </div>
    <h5 class="slider-card--title">{{ data.name }}</h5>
  </div>
</template>
