<script setup lang="ts">
import { computed, defineProps, defineEmits, toRefs } from 'vue';
import ArrowButton from '@/components/ArrowButton.vue';

const props = defineProps({
  currentIndex: {
    type: Number,
    required: true,
  },
  cardsNumber: {
    type: Number,
    required: true,
  },
  cardsPerRow: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  isMobile: {
    type: Boolean,
    required: true,
  },
});

const { currentIndex, cardsNumber, cardsPerRow } = toRefs(props);

const isPrevDisabled = computed(() => currentIndex.value === 0);
const isNextDisabled = computed(
  () =>
    currentIndex.value === Math.ceil(cardsNumber.value / cardsPerRow.value) - 1,
);

const emit = defineEmits(['update:currentIndex', 'update:direction']);

const nextSlide = () => {
  if (!isNextDisabled.value) {
    emit('update:direction', 'next');
    emit('update:currentIndex', currentIndex.value + 1);
  }
};

const prevSlide = () => {
  if (!isPrevDisabled.value) {
    emit('update:direction', 'prev');
    emit('update:currentIndex', currentIndex.value - 1);
  }
};
</script>

<template>
  <div class="slider-heading">
    <ArrowButton
      class="slider-arrow"
      :disabled="isPrevDisabled"
      direction="left"
      @click="prevSlide"
    />
    <h2 v-if="!isMobile" class="slider-heading-title">{{ title }}</h2>
    <ArrowButton
      class="slider-arrow"
      :disabled="isNextDisabled"
      direction="right"
      @click="nextSlide"
    />
  </div>
</template>

<style>
.slider-heading {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem 1rem;
  color: #4a5568;
  font-weight: 400;
  font-size: 1.125rem;
  text-transform: uppercase;
}
</style>
