<script setup lang="ts">
import type { PropType } from 'vue';

defineProps({
  disabled: {
    type: Boolean,
    required: true,
  },
  direction: {
    type: String as PropType<'left' | 'right'>,
    default: 'right',
  },
});

const emit = defineEmits(['click']);
</script>

<template>
  <button :disabled="disabled" class="arrow-button" @click="emit('click')">
    <font-awesome-icon
      :class="['arrow-icon', !disabled ? 'enabled' : 'disabled']"
      size="lg"
      :icon="['fas', `arrow-${direction}`]"
    />
  </button>
</template>

<style scoped>
.arrow-button {
  width: 1.25rem;
  height: 1.25rem;
}

.arrow-icon {
  font-size: 1.25rem;
  transition: color 0.3s ease-in;
}

.arrow-button:disabled .arrow-icon {
  color: #d1d5db;
}

.arrow-button:not(:disabled) .arrow-icon {
  color: #000000;
}
</style>
