import VueMatomo from 'vue-matomo';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import type { App } from 'vue';

export const mediaPluginWrapper = {
  install(GivenVue: App) {
    const Vue = GivenVue;

    Vue.component('font-awesome-icon', FontAwesomeIcon);
    Vue.use(VueMatomo, {
      requireConsent: false,
      host: 'https://log.streamdiver.com',
      trackerUrl: 'https://log.streamdiver.com/js/tracker.php',
      debug: true,
      enableLinkTracking: true,
      trackInitialView: true,
      disableCookies: false,
      requireCookieConsent: false,
      enableHeartBeatTimer: true,
      heartBeatTimerInterval: 15,
    });
  },
};
