import SdMediaWidget from './SdMediaWidget.ce.vue';
import SdCarouselWidget from '@/SdCarouselWidget.ce.vue';
import SdExtendedMediaWidget from '@/SdExtendedMediaWidget.ce.vue';

// ?inline can not handle import url() in css therefore fonts are not loaded, workaround is to add font css to the App.vue
import style from './style.css?inline';
import { mediaPluginWrapper } from './plugins/mediaWidget.ts';
import { pluginsWrapper } from './plugins/index';

import {
  defineCustomElement as VueDefineCustomElement,
  h,
  createApp,
  getCurrentInstance,
} from 'vue';
import { createWebComponent } from 'vue-web-component-wrapper';

/**
 * @deprecated
 * `sd-player-widget` is deprecated and will be removed in the next major version.
 * Please use `sd-media-widget` instead.
 */
createWebComponent({
  rootComponent: SdMediaWidget,
  elementName: 'sd-player-widget',
  plugins: mediaPluginWrapper,
  cssFrameworkStyles: style,
  VueDefineCustomElement,
  h,
  createApp,
  getCurrentInstance,
});

createWebComponent({
  rootComponent: SdMediaWidget,
  elementName: 'sd-media-widget',
  plugins: mediaPluginWrapper,
  cssFrameworkStyles: style,
  VueDefineCustomElement,
  h,
  createApp,
  getCurrentInstance,
});

createWebComponent({
  rootComponent: SdCarouselWidget,
  elementName: 'sd-carousel-widget',
  plugins: pluginsWrapper,
  cssFrameworkStyles: style,
  VueDefineCustomElement,
  h,
  createApp,
  getCurrentInstance,
});

createWebComponent({
  rootComponent: SdExtendedMediaWidget,
  elementName: 'sd-extended-media-widget',
  plugins: pluginsWrapper,
  disableShadowDOM: true,
  VueDefineCustomElement,
  h,
  createApp,
  getCurrentInstance,
});
