import VueMatomo from 'vue-matomo';
import type { App } from 'vue';
import type { MatomoConfig } from '@/types/matomo.ts';

export const matomoConfig: MatomoConfig = {
  state: {
    requireConsent: false,
    host: 'https://log.streamdiver.com',
    trackerUrl: 'https://log.streamdiver.com/js/tracker.php',
    debug: true,
    enableLinkTracking: true,
    trackInitialView: true,
    disableCookies: false,
    requireCookieConsent: false,
    enableHeartBeatTimer: true,
    heartBeatTimerInterval: 15,
  },
  isInitialized: false,
  tenantName: null,
};

export const initMatomo = (app: App): void => {
  if (!matomoConfig.isInitialized) {
    app.use(VueMatomo, matomoConfig.state);
    matomoConfig.isInitialized = true;
  }
};
