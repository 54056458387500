<script setup lang="ts">
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  type PropType,
  toRefs,
  watch,
} from 'vue';
import type { Recommendation } from '@/types/media.ts';
import Card from '@/components/Slider/SliderCard.vue';
import ModalMobile from '@/components/ModalMobile.vue';
import Modal from '@/components/Modal.vue';
import { useModal } from '@/composables/useModal.ts';
import { getTenantDesign } from '@/helpers/API.ts';
import type { TenantDesign } from '@/types/player-config.ts';
import SliderHeading from '@/components/Slider/SliderHeading.vue';

const props = defineProps({
  data: {
    type: Object as PropType<Recommendation[]>,
    required: true,
  },
  tenantName: {
    type: String,
    required: true,
  },
  accessToken: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  isMobile: {
    type: Boolean,
    required: true,
  },
  cardsPerRow: {
    type: Number,
    required: true,
  },
});

const { isMobile, data, accessToken, title, cardsPerRow } = toRefs(props);
const currentIndex = ref(0);
const direction = ref<'next' | 'prev'>('next');
const playerSettings = ref<TenantDesign>();
const { focusVideo, showModalVideo, handleFocusModalVideo } = useModal();
const modalMobile = ref(false);

const imageBasis = computed(() => `${100 / cardsPerRow.value}%`);
const totalPages = computed(() =>
  Math.ceil(data.value.length / cardsPerRow.value),
);

const visibleCards = computed(() => {
  const start = currentIndex.value * cardsPerRow.value;
  const end = start + cardsPerRow.value;
  const cards = data.value.slice(start, end);
  if (!isMobile.value) {
    return { cards };
  }
  const placeholders = Array(cardsPerRow.value - cards.length).fill(true);
  return { cards, placeholders };
});

const updateViewportSize = () => (modalMobile.value = window.innerWidth <= 800);

watch(cardsPerRow, () => {
  if (currentIndex.value > totalPages.value - 1) {
    currentIndex.value = totalPages.value - 1;
  }
});

onMounted(async () => {
  updateViewportSize();
  window.addEventListener('resize', updateViewportSize);
  const tenant = await getTenantDesign({ accessToken: accessToken.value });
  if (tenant) {
    playerSettings.value = tenant.data;
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateViewportSize);
});
</script>

<template>
  <div :class="['slider-container', { 'slider-container-mobile': isMobile }]">
    <SliderHeading
      :title="title"
      :isMobile="isMobile"
      :cards-per-row="cardsPerRow"
      :cardsNumber="data.length"
      :current-index="currentIndex"
      @update:currentIndex="(value) => (currentIndex = value)"
      @update:direction="(value) => (direction = value)"
    />
    <div class="slider-wrapper">
      <transition
        :name="direction === 'next' ? 'slide-next' : 'slide-prev'"
        mode="out-in"
      >
        <div :key="currentIndex" class="slider-content">
          <Card
            v-for="card in visibleCards.cards"
            :key="card.id"
            :data="card"
            :access-token="accessToken"
            @handleFocusVideo="handleFocusModalVideo"
          />
          <Card
            v-for="(placeholder, inx) in visibleCards.placeholders"
            :key="inx + Math.random()"
            :placeholder="placeholder"
          />
        </div>
      </transition>
    </div>
    <h2 v-if="isMobile" class="slider-container-mobile-title">{{ title }}</h2>
  </div>
  <Modal
    v-if="!modalMobile"
    :focusVideo="focusVideo"
    :tenantName="tenantName"
    :mediaList="data"
    :access-token="accessToken"
    :player-settings="playerSettings"
    @focusChange="handleFocusModalVideo"
  />
  <ModalMobile
    v-else
    :focusVideo="focusVideo"
    :tenantName="tenantName"
    :mediaList="data"
    :access-token="accessToken"
    :player-settings="playerSettings"
    @focusChange="handleFocusModalVideo"
    :showModalVideo="showModalVideo"
  />
</template>

<style>
.slider-container {
  padding: 1rem 0 1rem;
  border-top: 1px solid #e5e7eb;
}

.slider-wrapper {
  position: relative;
  overflow: hidden;
  justify-content: center;
  margin: 0 1rem;
}

.slider-content {
  display: flex;
  justify-content: center;
  transition: transform 0.15s linear;
  margin: 0 -0.375rem;
}

.slider-container-mobile .slider-content {
  flex-direction: column;
}

.slider-container-mobile {
  display: flex;
  flex-direction: column-reverse;
}

.slider-container-mobile-title {
  margin: 0 1rem;
  color: #4a5568;
  font-weight: 400;
  font-size: 1.125rem;
  text-transform: uppercase;
}

.slide-next-enter-active {
  transition: none;
  transform: translateX(100%);
}

.slide-next-leave-to {
  transform: translateX(-100%);
}

.slide-prev-enter-active {
  transition: none;
  transform: translateX(-100%);
}

.slide-prev-leave-to {
  transform: translateX(100%);
}

.slider-card {
  flex: 0 0 calc(v-bind(imageBasis));
  text-align: center;
  cursor: pointer;
  padding: 0 0.375rem;
}

.slider-card-placeholder {
  visibility: hidden;
}

.card-image {
  width: 100%;
  height: auto;
}

.slider-card-image-wrapper {
  position: relative;
  aspect-ratio: 16 / 9;
}

.play-icon {
  width: 2.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider-container-mobile .play-icon {
  width: 1.5rem;
}

.image-placeholder {
  width: 100%;
  height: 100%;
  background: #e0e0e0;
}

.slider-card--title {
  text-align: left;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.slider-container-mobile .slider-card {
  display: flex;
  padding: 1rem 0;
  border-bottom: 1px solid #e5e7eb;
}

.slider-container-mobile .slider-card div {
  width: 25%;
  margin-right: 0.375rem;
}

.slider-container-mobile .slider-card h5 {
  width: 75%;
  margin: 0 0 0 0.375rem;
}

.slider-container-mobile .slider-wrapper {
  margin-bottom: 0.5rem;
}
</style>
