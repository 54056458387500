<script setup lang="ts">
import { type ListItemProps, useListItem } from '@/composables/useListItem';
import PlayIcon from '@/public/playericon.png';

const props = defineProps<ListItemProps>();
const emit = defineEmits<(event: 'focusItem', item: ListItemProps) => void>();

const { imgSrc, imgEl, handleFocus, millisecondsToMinutesAndSeconds } =
  useListItem(props, emit);
</script>

<template>
  <div class="asset-list-item" @click="handleFocus(props)">
    <div class="thumbnail-wrapper">
      <img :src="imgSrc" class="thumbnail" ref="imgEl" alt="thumbnail" />
      <div
        class="absolute w-[250px] h-[140px] left-0 top-0 flex items-center justify-center"
      >
        <div class="w-10">
          <img class="w-10" :src="PlayIcon" alt="play" />
        </div>
      </div>
    </div>
    <div class="text-details">
      <span v-if="file?.durationInMilliseconds !== undefined">
        {{ millisecondsToMinutesAndSeconds(file.durationInMilliseconds) }}
      </span>
    </div>
    <h5 class="list-item-title">{{ name }}</h5>
  </div>
</template>

<style scoped>
.asset-list-item {
  flex: 0 0 250px;
  margin: 10px 0;
  height: 236px;
  cursor: pointer;

  .thumbnail-wrapper {
    position: relative;
  }

  .thumbnail {
    width: 250px;
    height: 140px;
  }

  .text-details {
    margin: 10px 0;
    font-size: 12px;
  }

  .list-item-title {
    font-size: 1.25rem;
    width: 250px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }
}
</style>
