import { request } from '@/helpers/index.ts';
import type {
  Asset,
  ChannelDetails,
  Chapter,
  ChapterBase,
  Media,
  Recommendations,
} from '@/types/media.ts';
import type { TenantDesign, TenantSettings } from '@/types/player-config.ts';
import type { ApiResponse, BasePayload } from '@/types/api.ts';

export async function fetchToken(tenantName: string, baseUrl?: string) {
  return await request<{ accessToken: string; expiresIn: number }>(
    `token/${tenantName}`,
    {
      baseUrl: baseUrl ?? import.meta.env.VITE_PUBLIC_PROXY_URL,
    },
  );
}

export async function getChannelDetails({
  id,
  accessToken,
  baseUrl,
}: { id: string } & BasePayload) {
  return await request<ApiResponse<ChannelDetails>>(`channels/${id}`, {
    accessToken,
    baseUrl,
  });
}

export async function getChannelMediaList({
  id,
  accessToken,
}: {
  id: string;
  accessToken: string;
}) {
  return await request<ApiResponse<Media[]>>(
    `channels/${id}/media?${new URLSearchParams({
      perpage: '50',
      sort: 'createdAtUtc:desc',
    })}`,
    { accessToken },
  );
}

export async function getTenantDesign({ accessToken, baseUrl }: BasePayload) {
  return await request<ApiResponse<TenantDesign>>('tenants/current/designs', {
    accessToken,
    baseUrl,
  });
}

export async function getTenantSettings({ accessToken, baseUrl }: BasePayload) {
  return await request<ApiResponse<TenantSettings>>(
    'tenants/current/settings',
    {
      accessToken,
      baseUrl,
    },
  );
}

export async function getThumbnail({
  url,
  accessToken,
}: {
  url: string;
  accessToken?: string;
}) {
  const res = await request<Response>('', {
    accessToken,
    raw: true,
    baseUrl: url,
  });
  return URL.createObjectURL(await res.blob());
}

export async function fetchMediaChapters({
  id,
  accessToken,
}: {
  id: string;
  accessToken: string;
}) {
  return await request<ApiResponse<ChapterBase[]>>(`media/${id}/chapters`, {
    accessToken,
  });
}

export async function fetchMediaChapter({
  id,
  accessToken,
  chapterId,
}: {
  id: string;
  accessToken: string;
  chapterId: string;
}) {
  return await request<ApiResponse<Chapter>>(
    `media/${id}/chapters/${chapterId}`,
    {
      accessToken,
    },
  );
}

export async function fetchAsset({
  baseUrl,
  id,
  accessToken,
}: { id: string } & BasePayload) {
  return await request<ApiResponse<Asset>>(`media/${id}`, {
    accessToken,
    baseUrl,
  });
}

export async function fetchBlob({
  url,
  accessToken,
}: {
  url: string;
  accessToken: string;
}) {
  const res = await request<Response>('', {
    accessToken,
    baseUrl: url,
    raw: true,
  });
  return await res.blob();
}

export async function fetchRecommendations({
  id,
  accessToken,
  channelId,
  perPage,
}: {
  id: string;
  accessToken: string;
  channelId?: string;
  perPage: number;
}) {
  const parsedChannelId = channelId
    ?.split(',')
    .map((id: string) => `channelIds=${id.trim()}`)
    .join('&');

  return await request<ApiResponse<Recommendations>>(
    `recommendations/?mediaIds=${id}${channelId ? '&' + parsedChannelId : ''}&perPage=${perPage}`,
    { accessToken },
  );
}
