import { APIError, type DisplayError } from '@/helpers/errors.ts';

type ErrorInfo = Record<number | string, DisplayError>;

export const useError = () => {
  const errorCodes = [400, 403];

  const getErrorMessage = (status: number): DisplayError => ({
    message: `error.${status}.message`,
    text: `error.${status}.text`,
  });

  const errors: ErrorInfo = errorCodes.reduce((acc: ErrorInfo, code) => {
    acc[code] = getErrorMessage(code);
    return acc;
  }, {});

  errors.default = {
    message: 'error.default.message',
    text: 'error.default.text',
  };

  const handleError = (err: unknown): DisplayError => {
    if (err instanceof APIError) {
      return errors[err.status] || errors.default;
    }
    return errors.default;
  };

  return {
    handleError,
    errors,
  };
};
