<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, type PropType } from 'vue';
import type { Recommendation } from '@/types/media.ts';
import Card from '@/components/Slider/SliderCard.vue';
import ModalMobile from '@/components/ModalMobile.vue';
import Modal from '@/components/Modal.vue';
import { useModal } from '@/composables/useModal.ts';
import { getTenantSettings } from '@/helpers/API.ts';
import type { TenantSettings } from '@/types/player-config.ts';
import { extWidgetRecommendationConfig } from '@/utils/grid.ts';
import SliderHeading from '@/components/Slider/SliderHeading.vue';

const { data, accessToken, title } = defineProps({
  data: {
    type: Object as PropType<Recommendation[]>,
    required: true,
  },
  tenantName: {
    type: String,
    required: true,
  },
  accessToken: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
});

const currentIndex = ref(0);
const direction = ref<'next' | 'prev'>('next');
const cardsPerRow = ref(4);
const isMobile = ref(false);
const playerSettings = ref<TenantSettings>();
const { focusVideo, showModalVideo, handleFocusModalVideo } = useModal();
const modalMobile = ref(false);

const containerEl = ref<HTMLElement>();
let resizeObserver: ResizeObserver | null = null;

const imageBasis = computed(() => `${100 / cardsPerRow.value}%`);

const updateCardsPerRow = (width: number) => {
  const config = extWidgetRecommendationConfig.find((c) => width <= c.maxWidth);
  isMobile.value = config!.isMobile;
  cardsPerRow.value = config!.cardsPerRow;
};

const visibleCards = computed(() => {
  const start = currentIndex.value * cardsPerRow.value;
  const end = start + cardsPerRow.value;

  return data.slice(start, end);
});

const updateViewportSize = () => (modalMobile.value = window.innerWidth <= 800);

onMounted(async () => {
  updateViewportSize();
  const updateCardsCols = () => {
    if (containerEl.value) {
      updateCardsPerRow(containerEl.value.offsetWidth);
    }
  };

  resizeObserver = new ResizeObserver(updateCardsCols);
  if (containerEl.value) {
    resizeObserver.observe(containerEl.value);
    updateCardsCols();
  }

  window.addEventListener('resize', updateViewportSize);
  const tenant = await getTenantSettings({ accessToken });
  if (tenant) {
    playerSettings.value = tenant.data;
  }
});

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.disconnect();
    resizeObserver = null;
  }
  window.removeEventListener('resize', updateViewportSize);
});
</script>

<template>
  <div ref="containerEl" class="slider-container">
    <SliderHeading
      :title="title"
      :cards-per-row="cardsPerRow"
      :cardsNumber="data.length"
      :current-index="currentIndex"
      @update:currentIndex="(value) => (currentIndex = value)"
      @update:direction="(value) => (direction = value)"
    />
    <div class="slider-wrapper">
      <transition
        :name="direction === 'next' ? 'slide-next' : 'slide-prev'"
        mode="out-in"
      >
        <div
          :key="currentIndex"
          class="slider-content"
          :class="{ 'slider-content-mobile': isMobile }"
        >
          <Card
            v-for="card in visibleCards"
            :key="card.id"
            :data="card"
            :tenantName="tenantName"
            :access-token="accessToken"
            @handleFocusVideo="handleFocusModalVideo"
          />
        </div>
      </transition>
    </div>
  </div>
  <Modal
    v-if="!modalMobile"
    :focusVideo="focusVideo"
    :tenantName="tenantName"
    :mediaList="data"
    :access-token="accessToken"
    :player-settings="playerSettings"
    @focusChange="handleFocusModalVideo"
  />
  <ModalMobile
    v-else
    :focusVideo="focusVideo"
    :tenantName="tenantName"
    :mediaList="data"
    :access-token="accessToken"
    :player-settings="playerSettings"
    @focusChange="handleFocusModalVideo"
    :showModalVideo="showModalVideo"
  />
</template>

<style>
.slider-container {
  background: rgb(245, 245, 245);
  padding: 1rem 0 1rem;
  border-top: 1px solid #e5e7eb;
}

.slider-wrapper {
  position: relative;
  overflow: hidden;
  justify-content: center;
  margin: 0 1rem;
}

.slider-content {
  display: flex;
  justify-content: center;
  transition: transform 0.15s linear;
  margin: 0 -0.375rem;
}

.slider-content-mobile {
  flex-direction: column;
}

.slide-next-enter-active {
  transition: none;
  transform: translateX(100%);
}

.slide-next-leave-to {
  transform: translateX(-100%);
}

.slide-prev-enter-active {
  transition: none;
  transform: translateX(-100%);
}

.slide-prev-leave-to {
  transform: translateX(100%);
}

.slider-card {
  flex: 0 0 calc(v-bind(imageBasis));
  text-align: center;
  cursor: pointer;
  padding: 0 0.375rem;
}

.card-image {
  width: 100%;
  height: auto;
}

.slider-card-image-wrapper {
  position: relative;
  aspect-ratio: 16 / 9;
}

.play-icon {
  width: 2.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-placeholder {
  width: 100%;
  height: 100%;
  background: #e0e0e0;
}

.slider-card--title {
  text-align: left;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}
</style>
