import { ref } from 'vue';
import type { ListItemProps } from '@/composables/useListItem.ts';

export const useModal = () => {
  const focusVideo = ref<ListItemProps | null>(null);
  const showModalVideo = ref(true);

  function handleFocusModalVideo(video: ListItemProps) {
    if (video) {
      focusVideo.value = video;
      showModalVideo.value = false;
      setTimeout(() => {
        showModalVideo.value = true;
      }, 300);
    } else {
      focusVideo.value = null;
    }
  }

  return {
    focusVideo,
    showModalVideo,
    handleFocusModalVideo,
  };
};
