<script setup lang="ts">
import { type ListItemProps, useListItem } from '@/composables/useListItem';
import PlayIcon from '@/public/playericon.png';

const props = defineProps<ListItemProps>();
const emit = defineEmits<(event: 'focusItem', item: ListItemProps) => void>();

const { imgSrc, imgEl, handleFocus, millisecondsToMinutesAndSeconds } =
  useListItem(props, emit);
</script>

<template>
  <div class="asset-list-item" @click="handleFocus(props)">
    <div class="thumbnail-wrapper">
      <img :src="imgSrc" class="thumbnail" ref="imgEl" alt="thumbnail" />
      <div class="absolute inset-0 flex items-center justify-center">
        <div class="w-10">
          <img class="w-10" :src="PlayIcon" alt="play" />
        </div>
      </div>
    </div>
    <div class="text-details">
      <span v-if="file?.durationInMilliseconds !== undefined">
        {{ millisecondsToMinutesAndSeconds(file.durationInMilliseconds) }}
      </span>
    </div>
    <h5 class="list-item-title">{{ name }}</h5>
  </div>
</template>

<style scoped>
.asset-list-item {
  @apply my-4 3xl:my-10  flex-[0_0_200px] h-[186px] cursor-pointer 3xl:h-[236px] 3xl:flex-[0_0_250px];

  .thumbnail-wrapper {
    @apply relative;
  }

  .thumbnail {
    @apply w-[200px] h-[112.5px] 3xl:w-[250px] 3xl:h-[140px];
  }

  .text-details {
    @apply my-2 3xl:my-3 text-xs;
  }

  .list-item-title {
    @apply text-base 3xl:text-xl w-[200px] 3xl:w-[250px] line-clamp-2;
  }
}
</style>
