import type { Payload } from '@/types/api.ts';
import { APIError } from '@/helpers/errors.ts';

export const request = async <T>(
  path: string,
  payload?: Payload,
  options: RequestInit = {},
): Promise<T> => {
  try {
    const response = await fetch(
      `${payload?.baseUrl ?? import.meta.env.VITE_API_URL}/${path}`,
      {
        method: options.method ?? 'GET',
        headers: {
          ...(payload?.accessToken && {
            Authorization: 'Bearer ' + payload.accessToken,
          }),
          ...options.headers,
        },
        ...options,
      },
    );

    if (!response.ok) {
      const error = await response.json();
      throw new APIError(response.status, response.url, error.message);
    }

    return payload?.raw ? response : response.json();
  } catch (error) {
    console.error('Request failed:', error);
    throw error;
  }
};
