<script setup lang="ts">
import ModalListItem from '@/components/ModalListItem.vue';
import { ref, computed, watch, type PropType } from 'vue';
import SdMediaWidget from '@/SdMediaWidget.ce.vue';
import type { Media, Recommendation } from '@/types/media.ts';
import type { TenantDesign } from '@/types/player-config.ts';
import type { ListItemProps } from '@/composables/useListItem.ts';

const props = defineProps({
  focusVideo: {
    type: Object as PropType<ListItemProps | null>,
  },
  playerSettings: {
    type: Object as PropType<TenantDesign>,
  },
  tenantName: {
    type: String,
    required: true,
  },
  mediaList: {
    type: Array as PropType<(Media | Recommendation)[]>,
  },
  accessToken: {
    type: String,
  },
  pageSize: {
    type: Number,
    default: 3,
  },
});

const emit = defineEmits(['closeModal', 'focusChange']);

const page = ref(0);
const isVisible = ref(false);

const channelMedia = computed(() => {
  if (props.mediaList) {
    return paginate(props.mediaList, props.pageSize);
  }
  return [];
});

const paginate = <T extends Media | Recommendation>(
  arr: T[],
  size: number,
): T[][] => {
  return arr.reduce((acc: T[][], val, i) => {
    const idx = Math.floor(i / size);
    const page = acc[idx] || (acc[idx] = []);
    page.push(val);
    return acc;
  }, []);
};

const handlePage = (number: number) => {
  if (!channelMedia.value) {
    return;
  }

  if (number < 0) {
    page.value = channelMedia.value.length - 1;
  } else if (number > channelMedia.value.length - 1) {
    page.value = 0;
  } else {
    page.value = number;
  }
};

const handleFocus = (video: ListItemProps) => emit('focusChange', video);

const handleCloseModal = () => (isVisible.value = false);

watch(
  () => props.focusVideo,
  (newValue) => {
    isVisible.value = !!newValue;
  },
);
</script>

<template>
  <transition name="fade" @after-leave="emit('focusChange')">
    <div class="modal" v-show="isVisible" @click.self="handleCloseModal">
      <div class="modal_content">
        <div class="button-row">
          <button class="w-3 h-3" @click="handleCloseModal">
            <font-awesome-icon
              class="text-sm text-black"
              size="lg"
              :icon="['fas', 'xmark']"
            />
          </button>
        </div>
        <div class="w-[650px] 3xl:w-[800px]">
          <div
            class="flex justify-center items-center w-[650px] max-h-[365.625px] h-[365.625px] 3xl:h-[450px] 3xl:max-h-[450px] 3xl:w-[800px] overflow-hidden bg-black"
          >
            <sd-media-widget
              v-if="focusVideo"
              width="100%"
              height="100%"
              :key="focusVideo?.id"
              :access-token="accessToken"
              :player-settings="playerSettings"
              :tenantName="tenantName"
              :assetId="focusVideo?.id"
            >
            </sd-media-widget>
          </div>
          <h2 class="modal_title">
            {{ focusVideo?.name }}
          </h2>
        </div>
        <ul class="asset-list">
          <modal-list-item
            @focusItem="handleFocus($event)"
            v-bind="media"
            :key="media.id"
            v-for="media in channelMedia[page]"
            :access-token="accessToken"
          />
        </ul>
        <div class="navigation-row">
          <div>
            <button class="w-5 h-5" @click="handlePage(page - 1)">
              <font-awesome-icon
                class="text-sm text-neutral-200 hover:text-black active:text-black ease-in transition-colors"
                size="lg"
                :icon="['fas', 'arrow-left']"
              />
            </button>
          </div>
          <div class="flex items-center gap-2">
            <button
              class="w-2 h-2"
              :class="index === page ? 'bg-black' : 'bg-neutral-200'"
              :key="`button-${index}`"
              v-for="(_, index) in channelMedia"
              @click="handlePage(index)"
            ></button>
          </div>
          <div>
            <button class="w-5 h-5" @click="handlePage(page + 1)">
              <font-awesome-icon
                class="text-sm text-neutral-200 hover:text-black active:text-black duration-300 ease-in transition-colors"
                size="lg"
                :icon="['fas', 'arrow-right']"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.modal {
  @apply fixed inset-0 bg-black bg-opacity-50 z-[10000] flex items-center justify-center;

  .modal_content {
    @apply p-6 3xl:p-8 bg-white flex flex-col items-center w-auto;
  }

  .button-row {
    @apply w-full flex justify-end items-center mb-4;
  }

  .modal_title {
    @apply text-xl 3xl:text-3xl mt-4;
  }

  .asset-list {
    @apply flex justify-center overflow-hidden gap-6;
  }

  .navigation-row {
    @apply flex justify-between items-center w-full;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
