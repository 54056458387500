import { createI18n } from 'vue-i18n';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import en from '@/locales/en.json';
import de from '@/locales/de.json';
import { initMatomo } from '@/matomo';
import type { App } from 'vue';

export const pluginsWrapper = {
  install(Vue: App) {
    const language = navigator.language || navigator.languages[0];

    const i18n = createI18n({
      locale: language.startsWith('de') ? 'de' : 'en',
      fallbackLocale: 'en',
      messages: { en, de },
      legacy: false,
    });

    library.add(
      faPlay,
      faArrowLeft,
      faArrowRight,
      faXmark,
      faChevronUp,
      faChevronDown,
    );
    Vue.component('font-awesome-icon', FontAwesomeIcon);
    Vue.use(i18n);
    initMatomo(Vue);
  },
};
