import { onBeforeMount, ref } from 'vue';
import { getThumbnail } from '@/helpers/API';
import type { Audit, Thumbnail, Type, Usage, File } from '@/types/media.ts';

export interface ListItemProps {
  id: string;
  accessToken?: string;
  name: string;
  audit: Audit;
  thumbnail: Thumbnail;
  file?: File;
  status?: string;
  type: Type;
  usage: Usage;
  tenantName?: string;
}

export function useListItem(
  props: ListItemProps,
  emit: (event: 'focusItem', item: ListItemProps) => void,
) {
  const imgSrc = ref<string>('');
  const imgEl = ref<HTMLImageElement | null>(null);

  function handleFocus(item: ListItemProps) {
    emit('focusItem', item);
  }

  function millisecondsToMinutesAndSeconds(milliseconds: number): string {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds > 9 ? remainingSeconds : '0' + remainingSeconds} MIN`;
  }

  onBeforeMount(async () => {
    if (props.thumbnail?.url && props.accessToken) {
      imgSrc.value = await getThumbnail({
        url: props.thumbnail.url,
        accessToken: props.accessToken,
      });
    }
  });

  return {
    imgSrc,
    imgEl,
    handleFocus,
    millisecondsToMinutesAndSeconds,
  };
}
