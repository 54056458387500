export interface DisplayError {
  message: string;
  text: string;
}

export class APIError extends Error {
  status: number;
  url: string;

  constructor(status: number, url: string, message: string) {
    super(message);
    this.status = status;
    this.url = url;
  }
}
